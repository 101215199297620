@critical {
	/*
		.frame-text-columns > .Container > .frame {
			-webkit-column-break-inside: avoid;
			break-inside: avoid;
			break-inside: avoid-column;
			page-break-inside: avoid;
		}
	*/
	.LandmarkMain__cancel {
		background-color: var(--color-orange);
		color: var(--color-white);
		text-align: center;
		padding: var(--layout-spacing--kilo) 0;

		& .Heading {
			margin-top: 0;
		}

		& p {
			padding: 0;
		}
	}

	@media (--breakpoint--kilo) {
		.LandmarkMain__wrap > * {
			margin-bottom: var(--layout-spacing--giga);

			& > .Heading + :not(.Container) {
				columns: 2;
				column-gap: var(--layout-spacing--mega);

				& :first-child {
					margin-top: 0;
				}
			}
		}
	}
}




