@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Misc.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Fonts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Dimensions.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Colors.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Breakpoints.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Visibility.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/MediaQueries.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Layouts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Hacks.css";
.TwoColumns {
	@media (--breakpoint--kilo) {
		display: flex;
		margin: 0 calc(0 - var(--font-size--uno));

		& > * {
			width: 50%;
			margin: 0 var(--font-size--uno);
		}
	}
}
