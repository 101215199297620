.EventList {
	@add-mixin reset-list;

	& li + li {
		margin-top: var(--layout-spacing--uno);
	}
}

.EventList__item {
	display: flex;
	align-items: center;
	text-decoration: none;

	&:hover, &:focus {
		background-color: var(--color-link--hover-background);
	}
}

.EventList__item-icon {
	width: 25%;

	& svg {
		vertical-align: middle;
	}
}

.EventList__item-text {
	padding-left: var(--font-spacing--uno);
	width: 75%;
}

.EventList__item-title {
	text-decoration: underline;

	.EventList__item:hover &,
	.EventList__item:focus & {
		color: var(--color-black);

	}
}

.EventList__item-date,
.EventList__item-location {
	color: var(--color-primary);
	font-weight: normal;
}

