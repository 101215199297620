.Location__name {
	font-weight: var(--font-weight--bold);
}

.Location__photos {
	@add-mixin reset-list;
}

.Location__wrapper {
	display: flex;
	@add-mixin avoid-column-break;
}

.Location__icon {
	padding-right: var(--layout-spacing--milli);
}

.Location__map-link {
	display: block;
	margin: var(--layout-spacing--milli) 0;
}

.Location__photo-description {
	font-size: var(--font-size--micro);
}
