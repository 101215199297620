.Heading {
	line-height: var(--line-height--headings);
	margin: var(--heading-spacing) 0;
	font-weight: var(--font-weight--bold);
}

/* Heading levels */
@each $size in xx-large, x-large, large, medium, small, x-small {
	.Heading--$(size) {
		font-size: var(--heading-size--$(size));
	}
}

.Heading--hidden {
	@add-mixin hide-element;
}
