@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Misc.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Fonts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Dimensions.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Colors.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Breakpoints.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Visibility.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/MediaQueries.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Layouts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Hacks.css";
.Heading {
	line-height: var(--line-height--headings);
	margin: var(--heading-spacing) 0;
	font-weight: var(--font-weight--bold);
}

/* Heading levels */
@each $size in xx-large, x-large, large, medium, small, x-small {
	.Heading--$(size) {
		font-size: var(--heading-size--$(size));
	}
}

.Heading--hidden {
	@add-mixin hide-element;
}

a {
	color: var(--color-link);
	font-weight: var(--font-weight--bold);

	&:hover {
		color: var(--color-link--hover);
	}

	&:focus {
		color: var(--color-link--focus);
	}
}

ul, ol {
	&.reset {
		@add-mixin reset-list;
	}
}

.font-size--micro {
	font-size: var(--font-size--micro);
}

.font-size--milli, small {
	font-size: var(--font-size--milli);
}

.font-size--uno {
	font-size: var(--font-size--uno);
}

.font-size--kilo {
	font-size: var(--font-size--kilo);
}

.font-size--mega {
	font-size: var(--font-size--mega);
}

.font-size--giga {
	font-size: var(--font-size--giga);
}

.font-size--tera {
	font-size: var(--font-size--tera);
}

.font-size--peta {
	font-size: var(--font-size--peta);
}
table {
	width: 100%;
	border-collapse: collapse;
}

th {
	text-align: left;
}