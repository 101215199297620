.Person__photo-wrap {
	text-align: left;
}

.Person__photo {
	border-radius: 50%;
	vertical-align: middle;
}

.Person__summary {
	font-weight: var(--font-weight--bold);
}

.Person__details {
	margin-top: var(--layout-spacing--uno);
}

.Person__contact {
	display: flex;
	flex-wrap: wrap;

	& dt, & dd {
		margin: 0;
	}

	& dt {
		width: 6em;
	}

	& dd {
		width: calc(100% - 6em);
		flex-shrink: 1;
	}

	&:empty {
		display: none;
	}
}

.PersonList {
	@add-mixin reset-list;

	& li + li {
		margin-top: var(--layout-spacing--giga);
	}

	@media (--breakpoint--kilo) {
		.LandmarkMain__wrap > * > .Heading + & {
			display: flex;
			flex-wrap: wrap;
			margin: calc(0 - var(--layout-spacing--uno));

			& li {
				width: calc(50% - 2 * var(--layout-spacing--uno));
				margin: var(--layout-spacing--uno);

				&:first-child {
					margin-top: var(--layout-spacing--uno);
				}
			}
		}
	}
}

.Person {
	@add-mixin avoid-column-break;
}
