.Event__summary {

}

.Event__start {
	font-weight: var(--font-weight--bold);
	display: block;
}

.Event__end {
	font-size: var(--font-size--micro);
	font-weight: var(--font-weight--normal);
	display: block;
	color: var(--color-white);
}
