@critical {
	@font-face {
		font-family: 'Roboto Slab';
		src: url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/robotoslab-bold.woff2') format('woff2'),
		url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/robotoslab-bold.woff') format('woff');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Roboto Slab';
		src: url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/robotoslab-regular.woff2') format('woff2'),
		url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/obotoslab-regular.woff') format('woff');
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}
}
