@critical {
	.frame-text-columns *:first-child {
		margin-top: 0;
	}

	.frame-text-columns > .Container > .frame {
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
		break-inside: avoid-column;
		page-break-inside: avoid;
	}


	@media (--breakpoint--kilo) {
		.frame-text-columns-2 .Container {
			columns: 2;
			column-gap: var(--layout-spacing--mega);
		}
	}

	.frame-space-after-medium {
		margin-bottom: var(--font-size--giga);
	}

	.video-embed {
		position: relative;
		height: 0;
		padding-top: 56.25%;
	}

	.video-embed-item {
		border: none;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
}

