.OrganizationList {
	@add-mixin reset-list;
	margin: calc(0 - var(--layout-spacing--uno));
	padding: 0;

	& li {
		margin: var(--layout-spacing--uno);
	}

	@media (--breakpoint--kilo) {
		display: flex;
		flex-wrap: wrap;

		& li {
			width: calc(50% - 2 * var(--layout-spacing--uno));
		}
	}
}

.OrganizationList__item {
	margin-bottom: var(--layout-spacing--giga);
}

.Organization {
	& .Organization__name {
		font-size: var(--font-size--uno);
		font-weight: var(--font-weight--normal);
		line-height: var(--line-height);
		margin: 0;
	}

	& .Organization__region-wrap,
	& .Organization__country-wrap,
	& .Organization__geo {
		display: none;
	}
}

.Organization__logo-wrap {
	padding: var(--layout-spacing--kilo) 0;
	height: calc(6em + 2 * var(--layout-spacing--kilo));
	display: flex;
	justify-items: left;
	align-content: center;
}

.Organization__logo {
}

.Organization__contact {
	display: flex;
	flex-wrap: wrap;

	& dt, & dd {
		margin: 0;
	}

	& dt {
		width: 6em;
	}

	& dd {
		width: calc(100% - 6em);
		flex-shrink: 1;
	}

	& a {
		word-wrap: break-word;
		overflow-wrap: break-word;
		hyphens: auto;
	}
}

.Event__sponsors-type {
	margin-top: var(--layout-spacing--kilo);

	& ~ .Event__sponsors-type {
		margin-top: var(--layout-spacing--giga);
	}

	& > .Heading {
		@add-mixin hide-element;
	}
}

.Sponsor__label {
	font-weight: var(--font-weight--bold);
	font-size: var(--heading-size--medium);
}

