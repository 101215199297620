a {
	color: var(--color-link);
	font-weight: var(--font-weight--bold);

	&:hover {
		color: var(--color-link--hover);
	}

	&:focus {
		color: var(--color-link--focus);
	}
}
