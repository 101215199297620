@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Misc.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Fonts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Dimensions.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Colors.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Breakpoints.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Visibility.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/MediaQueries.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Layouts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Hacks.css";
.LandmarkBanner {
	padding: var(--layout-spacing--uno) var(--layout-spacing--milli);
	text-align: center;
	color: var(--color-primary--inverse);
	background: var(--color-gradient); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM2NDY0NjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI2NyUiIHN0b3AtY29sb3I9IiMxMTExMTEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvcmFkaWFsR3JhZGllbnQ+CiAgPHJlY3QgeD0iLTUwIiB5PSItNTAiIHdpZHRoPSIxMDEiIGhlaWdodD0iMTAxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: radial-gradient(ellipse at center, var(--color-gradient) 0%, var(--color-primary) 67%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--color-gradient)', endColorstr='var(--color-primary)', GradientType=1);
}

.LandmarkBanner__wrap {
	max-width: var(--layout-width--banner);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.LandmarkBanner__title.Heading {
	line-height: var(--line-height--headings-less);
	--width: 20%;

	@media (--breakpoint--kilo) {
		position: relative;
		border-top: var(--border-width--banner-title) solid var(--color-primary--inverse);
		padding: var(--heading-spacing--third) 0 var(--heading-spacing--half);
		font-size: var(--font-size--peta);
		margin-top: 0;
		margin-bottom: 0;
		order: 2;

		&::before,
		&::after {
			content: " ";
			display: block;
			width: 20%;
			width: var(--width);
			transition: width .2s ease-out;
			height: var(--border-width--banner-title);
			background-color: var(--color-primary--inverse);
			position: absolute;
			top: 100%;
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}
	}
}

.LandmarkBanner__logo {
	width: 200px;
	height: 200px;
	margin: 0 auto;

	&, &:hover {
		color: var(--color-white);
	}

	& svg {
		fill: currentColor;
	}

	@media (--breakpoint--kilo) {
		margin-top: var(--layout-spacing--mega);
		margin-bottom: var(--layout-spacing--kilo);
		order: 1;
	}
}

.LandmarkBanner__subtitle.LandmarkBanner__subtitle {
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--mega);
	color: var(--color-orange);
	margin: var(--font-spacing--uno) auto;
	order: 3;
	line-height: var(--line-height--headings);

	@media (--breakpoint--kilo) {
		max-width: calc(60% - 2 * var(--font-spacing--milli));
		margin-top: calc(0 - var(--font-spacing--milli));
	}
}

.LandmarkBanner__location  {
	order: 3;
	text-decoration: none;
	color: var(--color-primary--inverse);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--micro);
	line-height: var(--line-height);
	margin: var(--layout-spacing--uno) auto;
	display: flex;


	&:hover, &:focus {
		& address {
			color: var(--color-primary--inverse);
		}
	}

	& .Location__marker {
		width: 3.5rem;
		padding-right: var(--layout-spacing--milli);

		& svg {
			fill: currentColor;
			width: 100%;
			height: auto;
		}
	}

	& address {
		text-align: left;
	}
}

.LandmarkMain__cancel p,
.LandmarkMain__wrap,
.LandmarkFooter {
	max-width: var(--layout-width);
	padding: var(--layout-spacing--kilo) var(--layout-spacing--uno);
	margin: 0 auto;
}

.LandmarkFooter {
	border-top: var(--border-width--banner-title) solid var(--color-grey--light);
}

@critical {
	/*
		.frame-text-columns > .Container > .frame {
			-webkit-column-break-inside: avoid;
			break-inside: avoid;
			break-inside: avoid-column;
			page-break-inside: avoid;
		}
	*/
	.LandmarkMain__cancel {
		background-color: var(--color-orange);
		color: var(--color-white);
		text-align: center;
		padding: var(--layout-spacing--kilo) 0;

		& .Heading {
			margin-top: 0;
		}

		& p {
			padding: 0;
		}
	}

	@media (--breakpoint--kilo) {
		.LandmarkMain__wrap > * {
			margin-bottom: var(--layout-spacing--giga);

			& > .Heading + :not(.Container) {
				columns: 2;
				column-gap: var(--layout-spacing--mega);

				& :first-child {
					margin-top: 0;
				}
			}
		}
	}
}




