@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Misc.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Fonts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Dimensions.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Colors.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Breakpoints.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Visibility.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/MediaQueries.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Layouts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Hacks.css";
.Subheader {
  margin-bottom: var(--layout-spacing--micro);
}

.Subheader + .Heading {
  margin-top: 0;
}
