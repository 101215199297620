.PresentationList__caption {
	text-align: left;
}

.PresentationList__time {
	width: 7em;
}

.PresentationList__time,
.PresentationList__item {
	vertical-align: top;
}

.Presentation__performer-list {
	@add-mixin reset-list;
	display: inline;
}

.Presentation__performer {
	display: inline;
	position: relative;

	& + .Presentation__performer {
		padding-left: .5em;

		&::before {
			position: absolute;
			left: 0;
			content: ', ';
		}
	}
}

.Presentation__info[open] + .Presentation__performers {
	margin-bottom: var(--layout-spacing--uno);
}

.Presentation__summary {
	cursor: default;
	outline-color: var(--color-link--focus);
}

.Presentation__documented {
	display: inline-block;
	width: 1em;
	height: 1em;
	margin-top: -.2em !important;
	margin-left: var(--layout-spacing--pico);
	vertical-align: middle;
	@add-mixin hide-content;
	background-image: url('/typo3conf/ext/tw_events/Resources/Public/Icons/Note.svg');
	background-size: contain;
	background-repeat: no-repeat;
}

.Presentation__documentation {
	@add-mixin reset-list;

	& li {
		display: inline-block;

		& + li::before {
			position: static;
			content: ' | ';
		}
	}
}

.Presentation__subtitle {
	columns: 1 !important;
}

.Presentation__notes-list {
	@add-mixin reset-list;

	& li + li {
		margin-top: var(--layout-spacing--uno);
	}
}

.Presentation__note-content {
	background-color: var(--color-grey--light);
	padding: var(--layout-spacing--uno);

	& > *:first-child {
		margin-top: 0;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.Presentation__coverage-list {
	& li + li {
		margin-top: var(--layout-spacing--uno);
	}

	@media (--breakpoint--kilo) {
		columns: 2;
		column-gap: var(--layout-spacing--mega);
	}
}

.Presentation__videos-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

	& li {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 0;
		height: 0;
		overflow: hidden;
	}

	& li + li {
		margin-top: var(--layout-spacing--mega);
	}

	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}

.Presentation > section {
	margin-top: var(--layout-spacing--uno);

	@media (--breakpoint--kilo) {
		margin-top: var(--layout-spacing--giga);
	}
}
