@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Misc.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Fonts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Dimensions.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Colors.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Breakpoints.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Visibility.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/MediaQueries.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Layouts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Hacks.css";
@critical {
	@font-face {
		font-family: 'Roboto Slab';
		src: url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/robotoslab-bold.woff2') format('woff2'),
		url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/robotoslab-bold.woff') format('woff');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Roboto Slab';
		src: url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/robotoslab-regular.woff2') format('woff2'),
		url('/typo3conf/ext/tw_a11yclub/Resources/Public/Fonts/obotoslab-regular.woff') format('woff');
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
	outline-color: var(--color-outline);
}

body, button, input, select, textarea {
	font: var(--font-weight--normal) var(--font-size--uno)/var(--line-height) var(--font-family);
	font-weight: var(--font-weight--normal);
	letter-spacing: var(--letter-spacing);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-primary);
}

html {
	position: relative;
	z-index: 0;
}

@media (--breakpoint--micro) {
	html {
		z-index: 100;
	}
}

@media (--breakpoint--milli) {
	html {
		z-index: 200;
	}
}

@media (--breakpoint--uno) {
	html {
		z-index: 300;
	}
}

@media (--breakpoint--kilo) {
	html {
		z-index: 400;
	}
}

@media (--breakpoint--mega) {
	html {
		z-index: 500;
	}
}

body {
	margin: 0;
	background-color: var(--bgcolor-body);
}

main, aside, header, footer, figure, details, summary {
	display: block;
}

figure {
	margin: 0;
}

img, object, video, audio {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

::selection {
	/* background-color: #123456; */
}

template {
	display: none;
}

address {
	font-style: normal;
}

button {
	vertical-align: middle;
}

time {
	white-space: nowrap;
}

.hide {
	display: none;
}

.hide-content {
	@add-mixin hide-content;
}

.hide-element {
	@add-mixin hide-element;
}

[hidden] {
	display: none !important;
}

.clearfix:after {
	content: "";
	display: table;
	clear: both;
}

.wrap {
	word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
}

@critical {
	.frame-text-columns *:first-child {
		margin-top: 0;
	}

	.frame-text-columns > .Container > .frame {
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
		break-inside: avoid-column;
		page-break-inside: avoid;
	}


	@media (--breakpoint--kilo) {
		.frame-text-columns-2 .Container {
			columns: 2;
			column-gap: var(--layout-spacing--mega);
		}
	}

	.frame-space-after-medium {
		margin-bottom: var(--font-size--giga);
	}

	.video-embed {
		position: relative;
		height: 0;
		padding-top: 56.25%;
	}

	.video-embed-item {
		border: none;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
}

