@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Misc.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Fonts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Dimensions.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Colors.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Variables/Breakpoints.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Visibility.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/MediaQueries.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Layouts.css";
@import "../../../../../../public/typo3conf/ext/tw_a11yclub/Configuration/Css/Mixins/Hacks.css";
.Event__summary {

}

.Event__start {
	font-weight: var(--font-weight--bold);
	display: block;
}

.Event__end {
	font-size: var(--font-size--micro);
	font-weight: var(--font-weight--normal);
	display: block;
	color: var(--color-white);
}

.LandmarkMain .Event__basics .Heading + p {
	columns: 1;

	& .Event__end {
		color: var(--color-primary);
	}
}

.Event__ticket-cta {
	margin-top: var(--layout-spacing--uno);

	@media (--breakpoint--kilo) {
		column-count: 1 !important;
		width: calc(50% - var(--layout-spacing--uno));
	}
}

.Event__ticket-cta,
.cta {
	& a {
		padding: var(--font-spacing--uno) var(--font-spacing--kilo);
		background-color: var(--color-link);
		color: var(--color-primary--inverse);
		text-decoration: none;
		display: block;
		text-align: center;

		&:hover, &:focus {
			color: var(--color-primary--inverse);
			background-color: var(--color-orange);
		}
	}
}

.Event__coverage-list {
	& li + li {
		margin-top: var(--layout-spacing--uno);
	}
}

.EventList {
	@add-mixin reset-list;

	& li + li {
		margin-top: var(--layout-spacing--uno);
	}
}

.EventList__item {
	display: flex;
	align-items: center;
	text-decoration: none;

	&:hover, &:focus {
		background-color: var(--color-link--hover-background);
	}
}

.EventList__item-icon {
	width: 25%;

	& svg {
		vertical-align: middle;
	}
}

.EventList__item-text {
	padding-left: var(--font-spacing--uno);
	width: 75%;
}

.EventList__item-title {
	text-decoration: underline;

	.EventList__item:hover &,
	.EventList__item:focus & {
		color: var(--color-black);

	}
}

.EventList__item-date,
.EventList__item-location {
	color: var(--color-primary);
	font-weight: normal;
}

