.Event__ticket-cta {
	margin-top: var(--layout-spacing--uno);

	@media (--breakpoint--kilo) {
		column-count: 1 !important;
		width: calc(50% - var(--layout-spacing--uno));
	}
}

.Event__ticket-cta,
.cta {
	& a {
		padding: var(--font-spacing--uno) var(--font-spacing--kilo);
		background-color: var(--color-link);
		color: var(--color-primary--inverse);
		text-decoration: none;
		display: block;
		text-align: center;

		&:hover, &:focus {
			color: var(--color-primary--inverse);
			background-color: var(--color-orange);
		}
	}
}

.Event__coverage-list {
	& li + li {
		margin-top: var(--layout-spacing--uno);
	}
}
