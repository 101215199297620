.Gallery {
	margin: calc(0 - var(--layout-spacing--pico));
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}

.Gallery__item {
	padding: var(--layout-spacing--pico);
	vertical-align: top;
	@add-mixin flex-basis 100%;
}

@media (--breakpoint--kilo) {
	.Gallery__item {
		@add-mixin flex-basis 50%;
	}
}

@media (--breakpoint--mega) {
	.Gallery__item {
		@add-mixin flex-basis calc(100% / 3);
	}
}