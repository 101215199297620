.TwoColumns {
	@media (--breakpoint--kilo) {
		display: flex;
		margin: 0 calc(0 - var(--font-size--uno));

		& > * {
			width: 50%;
			margin: 0 var(--font-size--uno);
		}
	}
}
